<template>
  <section class="app-ecommerce-details">
    <b-card
        v-if="set"
        no-body
    >
      <b-card-body>
        <b-row class="my-2">
          <!-- Right: set Details -->
          <b-col
              cols="12"
              class="ml-2"
          >
            <!-- set Name -->
            <h4 class="font-weight-bolder" >{{ set.name }}</h4>
            <b-card-text class="item-company mb-0">
              <div v-html="set.description" />
            </b-card-text>
          </b-col>
          <b-col
              v-if="set.objects.length"
              cols="12"
          >
            <b-card>
              <b-row>
                <b-col v-if="ifAsset"
                       cols="12"
                       class="mt-2"
                >
                  <h5 class="mb-1 font-weight-bolder" >
                    {{ $t("Activos") }}
                  </h5>
                  <b-list-group>
                    <b-list-group-item
                        v-for="(p, index) in set.objects"
                        v-if="p.type === 'asset'"
                        :key="index"
                    >
                      <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                          <b-link
                              v-if="isMobile()"
                              :to="{
                          name: 'publicViewAsset',
                          params: { id: p.object.id },
                        }"
                              class="d-flex justify-content-start align-items-center"
                          >
                            <div
                                v-if="p.object.product"
                                :style="`background-image: url('${p.object.product.docs.imagen}');`"
                                class="d-inline-block img-product-list mr-50"
                            />
                            <div>
                              <p class="m-0">
                                {{ p.object.name }}
                              </p>
                              <b-badge
                                  v-if="p.object.status"
                                  :variant="statusAssets[p.object.status.alias]"
                              >
                                {{ $t("status.assets." + p.object.status.alias) }}
                              </b-badge>
                            </div>
                          </b-link>
                          <b-link
                              v-else
                              :to="{
                          name: 'viewAsset',
                          params: { id: p.object.id },
                        }"
                              class="d-flex justify-content-start align-items-center"
                          >
                            <div
                                v-if="p.object.product"
                                :style="`background-image: url('${p.object.product.docs.imagen}');`"
                                class="d-inline-block img-product-list mr-50"
                            />
                            <div>
                              <p class="m-0">
                                {{ p.object.name }}
                              </p>
                              <b-badge
                                  v-if="p.object.status"
                                  :variant="statusAssets[p.object.status.alias]"
                              >
                                {{ $t("status.assets." + p.object.status.alias) }}
                              </b-badge>
                            </div>
                          </b-link>
                        </b-col>
                        <b-col
                            v-if="p.object.last_review_date"
                            cols="12"
                            md="6"
                            class="d-flex align-items-center mt-1 mt-md-0"
                        >
                          <strong>{{ $t("Última revisión") }}: </strong><span class="ml-50">{{
                            TimestampToFlatPickr(p.object.last_review_date)
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col v-if="ifProduct"
                       cols="12"
                       class="mt-2"
                >
                  <h5 class="mb-1 font-weight-bolder">
                    {{ $t("Productos") }}
                  </h5>
                  <b-list-group>
                    <b-list-group-item
                        v-for="(p, index) in set.objects"
                        v-if="p.type === 'product'"
                        :key="index"
                    >
                      <b-row>
                        <b-col
                            cols="12"
                            md="6"
                        >
                          <b-link
                              v-if="isMobile()"
                              :to="{
                          name: 'publicViewProduct',
                          params: { id: p.object.id },
                        }"
                              class="d-flex justify-content-start align-items-center"
                          >
                            <div
                                v-if="p.object.imagen"
                                :style="`background-image: url('${p.object.imagen}');`"
                                class="d-inline-block img-product-list mr-50"
                            />
                            <div>
                              <p class="m-0">
                                {{ p.object.CodAndName }}
                              </p>
                            </div>
                          </b-link>
                          <b-link
                              v-else
                              :to="{
                          name: 'viewProduct',
                          params: { id: p.object.id },
                        }"
                              class="d-flex justify-content-start align-items-center"
                          >
                            <div
                                v-if="p.object.imagen"
                                :style="`background-image: url('${p.object.imagen}');`"
                                class="d-inline-block img-product-list mr-50"
                            />
                            <div>
                              <p class="m-0">
                                {{ p.object.CodAndName }}
                              </p>
                            </div>
                          </b-link>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BCard, BCardBody, BRow, BCol, BListGroup, BCardText, BLink, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { TimestampToFlatPickr } from '@/libs/helpers'
import { config } from '@/shared/app.config'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BBadge,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusAssets: config.statusAssetsVariants,
      ifAsset: 0,
      ifProduct: 0,
    }
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      set: 'sets/getSet',
    }),
  },
  methods: {
    ...mapActions({
      getset: 'sets/getSet',
    }),
    isMobile() {
      if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent,
          )
      ) {
        return true
      }
      return false
    },
  },
  async created() {
    await this.getset(this.$route.params.id)
    if (this.set.objects.length) {
      this.set.objects.forEach(function (entry) {
        if (entry.type === 'asset') {
          this.ifAsset = 1
        }
        if (entry.type === 'product') {
          this.ifProduct = 1
        }
      }, this)
    }
  },
}
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
