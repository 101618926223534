var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"app-ecommerce-details"},[(_vm.set)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',{staticClass:"my-2"},[_c('b-col',{staticClass:"ml-2",attrs:{"cols":"12"}},[_c('h4',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.set.name))]),_c('b-card-text',{staticClass:"item-company mb-0"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.set.description)}})])],1),(_vm.set.objects.length)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[(_vm.ifAsset)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-1 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Activos"))+" ")]),_c('b-list-group',_vm._l((_vm.set.objects),function(p,index){return (p.type === 'asset')?_c('b-list-group-item',{key:index},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isMobile())?_c('b-link',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"to":{
                        name: 'publicViewAsset',
                        params: { id: p.object.id },
                      }}},[(p.object.product)?_c('div',{staticClass:"d-inline-block img-product-list mr-50",style:(("background-image: url('" + (p.object.product.docs.imagen) + "');"))}):_vm._e(),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(p.object.name)+" ")]),(p.object.status)?_c('b-badge',{attrs:{"variant":_vm.statusAssets[p.object.status.alias]}},[_vm._v(" "+_vm._s(_vm.$t("status.assets." + p.object.status.alias))+" ")]):_vm._e()],1)]):_c('b-link',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"to":{
                        name: 'viewAsset',
                        params: { id: p.object.id },
                      }}},[(p.object.product)?_c('div',{staticClass:"d-inline-block img-product-list mr-50",style:(("background-image: url('" + (p.object.product.docs.imagen) + "');"))}):_vm._e(),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(p.object.name)+" ")]),(p.object.status)?_c('b-badge',{attrs:{"variant":_vm.statusAssets[p.object.status.alias]}},[_vm._v(" "+_vm._s(_vm.$t("status.assets." + p.object.status.alias))+" ")]):_vm._e()],1)])],1),(p.object.last_review_date)?_c('b-col',{staticClass:"d-flex align-items-center mt-1 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v(_vm._s(_vm.$t("Última revisión"))+": ")]),_c('span',{staticClass:"ml-50"},[_vm._v(_vm._s(_vm.TimestampToFlatPickr(p.object.last_review_date)))])]):_vm._e()],1)],1):_vm._e()}),1)],1):_vm._e(),(_vm.ifProduct)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-1 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Productos"))+" ")]),_c('b-list-group',_vm._l((_vm.set.objects),function(p,index){return (p.type === 'product')?_c('b-list-group-item',{key:index},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.isMobile())?_c('b-link',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"to":{
                        name: 'publicViewProduct',
                        params: { id: p.object.id },
                      }}},[(p.object.imagen)?_c('div',{staticClass:"d-inline-block img-product-list mr-50",style:(("background-image: url('" + (p.object.imagen) + "');"))}):_vm._e(),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(p.object.CodAndName)+" ")])])]):_c('b-link',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"to":{
                        name: 'viewProduct',
                        params: { id: p.object.id },
                      }}},[(p.object.imagen)?_c('div',{staticClass:"d-inline-block img-product-list mr-50",style:(("background-image: url('" + (p.object.imagen) + "');"))}):_vm._e(),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(p.object.CodAndName)+" ")])])])],1)],1)],1):_vm._e()}),1)],1):_vm._e()],1)],1)],1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }